import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { ReactComponent as ArrowRight } from '../svg/icon/icon-arrow-right.svg';

interface BlogRollProps {
  data: any;
  count: number;
}

const BlogRoll: React.FC<BlogRollProps> = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <section className="lg:grid lg:grid-cols-2 lg:grid-flow-row lg:gap-8">
      {posts &&
        posts.map(({ node: post }) => (
          <article key={post.id} className="flex flex-wrap section-y-sm ">
            <header className="w-full">
              {post.frontmatter.featuredimage && (
                <Link to={post.fields.slug}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                    }}
                  />
                </Link>
              )}
              <div className="flex-1 section-y-xs">
                <Link to={post.fields.slug}>
                  <h4>{post.frontmatter.title}</h4>
                </Link>
                <p className="text-sm text-gray-700">{post.frontmatter.date}</p>
              </div>
            </header>
            <div>
              <p className="w-full">{post.excerpt}</p>
              <Link to={post.fields.slug} className="btn-link">
                Keep Reading <ArrowRight className="ml-2" />
              </Link>
            </div>
          </article>
        ))}
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
