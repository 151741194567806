import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import { Hero } from '../../components/design';

export const query = graphql`
  query BlogIndexQuery {
    file(sourceInstanceName: { eq: "uploads" }, name: { eq: "blog-index" }) {
      childImageSharp {
        fluid(maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const BlogPage = () => {
  const { file: image } = useStaticQuery(query);

  return (
    <Layout>
      <Hero
        title="Blog"
        subheading="Varius quam quisque id diam. Ipsum dolor sit amet consectetur adipiscing elit
            pellentesque habitant."
        image={image}
      />

      <section className="section bg-gray-100">
        <BlogRoll />
      </section>
    </Layout>
  );
};

export default BlogPage;
